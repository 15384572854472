<template>
  <v-card
    v-if="billingDetails"
    class="mt-4"
  >
    <v-card-title data-cy="contact-details-card-title">
      <span v-t="'billing.contactDetails'" />
      <v-checkbox
        v-model="contactSameAsBilling"
        :label="$t('billing.sameAsBillingDetails')"
        class="ml-12 mr-2 detail-card-same-as-billing-checkbox"
        data-cy="contact-checkbox"
        hide-details
        @change="payload => setContactSameAsBilling({ clientId, contactSameAsBilling: payload })"
      />
      <v-spacer />
      <core-element-errors-message
        class="mb-0 mr-2 error-in-contact-details-header"
        :show-errors="showApiErrors"
        :errors="errors"
      />
      <v-spacer />
      <material-billing-edit-details-modal-window
        v-if="!billingDetails.contactSameAsBilling"
        :title="$t('billing.checkoutEditContactInfo')"
        :on-submit="setContactDetails"
      />
    </v-card-title>
    <v-divider v-if="!contactSameAsBilling" />
    <v-card-text
      v-if="!contactSameAsBilling"
      data-cy="contact-details"
    >
      <v-row
        v-if="!contactSameAsBilling"
        class="flex-row-reverse"
        data-cy="contact-details-card"
      >
        <v-col
          v-for="{ key, labelKey } in BILLING_DETAIL_LABELS"
          :key="key"
          class="pt-0"
          cols="6"
        >
          <div class="overline">
            <!-- eslint-disable-next-line vue-i18n/no-dynamic-keys -->
            <span v-t="labelKey" />
          </div>
          <span :data-cy="`edited-contact-${key}`">
            {{ key === 'state' ? stateName : billingDetailContact[key] }}
          </span>
        </v-col>
      </v-row>

      <div
        v-else
        v-t="'billing.youDoNotHaveSavedContactDetailsYet'"
        class="text-center text--secondary"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { result } from 'lodash'
import { mapGetters, mapActions, mapState } from 'vuex'
import { BILLING_DETAIL_LABELS } from '@/constants/app'

export default {
  data () {
    return {
      BILLING_DETAIL_LABELS,
      contactSameAsBilling: false
    }
  },
  computed: {
    ...mapState('billingDetails', ['errors', 'showApiErrors']),
    ...mapGetters('client', { billingDetailsGetter: 'billingDetails' }),
    clientId () {
      return this.$route.params.clientId
    },
    billingDetails () {
      return this.billingDetailsGetter(this.$route.params.clientId)
    },
    billingDetailContact () {
      return this.billingDetails.contact
    },
    stateName () {
      return result(this.billingDetails.contact, 'state.value')
    }
  },
  mounted () {
    this.contactSameAsBilling = this.billingDetails.contactSameAsBilling
  },
  updated () {
    this.contactSameAsBilling = this.billingDetails.contactSameAsBilling
  },
  methods: {
    ...mapActions('billingDetails', ['setContactDetails', 'setContactSameAsBilling'])
  }
}
</script>

<style scoped>
  .detail-card-same-as-billing-checkbox {
    padding: 0;
    margin: 0;
  }

  .error-in-contact-details-header, .error-in-contact-details-header >>> i {
    font-size: 0.875rem;
    padding: 0 16px;
    min-height: 36px;
    line-height: inherit;
  }
  .error-in-contact-details-header >>> i {
    margin-right: 8px;
    padding: 0;
    min-width: 0;
  }
</style>
